// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// Redirect logo to main site
$(".mainLogo").prop("href", "https://spurgeons.org/");

// Gift aid
$('.donationGiftAid label.checkboxRadio').first().append('<br /><br />Add 25% more to your donation at no cost to you. A Gift Aid declaration allows Spurgeons to claim tax back on eligible donations. It means that for every £1 you donate to Spurgeons we can claim back 25p, at no extra cost to you.<!--<br /><br /><a href="#">Tell me more</a>-->');
  
$('.formSecondStep.giftAidStatement').first().html('Yes, I would like to claim Gift Aid. By ticking the "Yes" box, I agree I would like Spurgeons to reclaim the tax on all qualifying donations I have made, as well as any future donations, until I notify them otherwise. I understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in that tax year I may be asked to pay any difference. I understand that Spurgeons will reclaim 25p of tax on every £1 that I give.');

